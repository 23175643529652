.cookieSections {
  margin-bottom: 120px;
}

.legalImage {
  max-width: 630px;
}

@media screen and (max-width: 718px) {
  .legalImage {
    max-width: calc(100vw - 88px);
  }
}
