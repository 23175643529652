* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
}

body {
  background: #fff;
  overflow-x: hidden;

  /* Hide horizontal scrollbar */
}

a {
  text-decoration: none !important;
}

/* .body_modalOpen {

    overflow-y: hidden;
    height: 100vh;
} */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  height: 100%;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 95px;
  object-fit: contain;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.container {
  position: relative;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 31;
  margin: 0 auto;
}

.top__title {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 260px;
}

.top__h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 120%;
  /* identical to box height, or 77px */

  text-align: center;
  letter-spacing: -0.02em;

  color: #131013;
}

.top__title__blue {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  /* identical to box height, or 34px */

  text-align: center;
  letter-spacing: -0.05em;

  /* blue */

  color: #3830fb;
}

.section {
  /* max-width: 1420px; */
  width: 100%;
  min-height: 483px;
  height: 100%;
  border-radius: 40px;
  /* margin: 10px 0; */
}

.sectionWrap {
  position: relative;
  display: flex;
  margin: 0 auto;
  max-width: 1484px;
  width: 100%;
}

.sectionWrap_noFlex {
  display: block;
}

.section:first-child {
  margin: 0;
}

.section:last-child {
  margin: 0;
}

.nav__registration {
  display: flex;
  position: relative;
}

.nav__registration_in,
.nav__registration_up {
  position: relative;
  z-index: 10;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  cursor: pointer;
  letter-spacing: -0.03em;
  color: #000;
  width: 86px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s all ease-in-out;
  mix-blend-mode: normal;
  border-radius: 12px;
}

:is(.zindexsht .nav_NoScrolled) .nav__registration_in {
  color: #fff;
}

:is(.zindexsht .nav_NoScrolled) .nav__registration_up {
  color: #000;
}

:is(.zindexsht .nav_scrolled) .nav__registration_in {
  color: #000;
}

:is(.zindexsht .nav_scrolled) .nav__registration_up {
  color: #fff;
}

:is(.zindexsht .nav_scrolled) .nav__registration_in:hover {
  color: #fff;
}

.nav__registration_in {
  margin-right: 10px;
}

.nav_NoScrolled .nav__registration_up {
  color: #fff;
}

.nav__registration_up {
  color: #fff;
}

.nav__registration__bg {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 86px;
  height: 40px;
  background: #000;
  mix-blend-mode: normal;
  border-radius: 12px;
  transform: translateX(110%);
  transition: 0.5s all ease-in-out;
}

:is(.zindexsht .nav_NoScrolled) .nav__registration__bg {
  background: #fff;
}

.nav_NoScrolled .nav__registration__bg {
  background: #000;
}

.nav__registration_in:hover ~ .nav__registration__bg {
  transform: translateX(0%) !important;
}

.nav_NoScrolled
  .nav__registration_in:hover
  ~ .nav_NoScrolled
  .nav__registration_up {
  color: #fff;
}

.nav_NoScrolled .nav__registration_in:hover {
  color: #000;
}

:is(.aboutUs .nav_NoScrolled) .nav__registration_in:hover {
  color: #fff;
}

.nav_NoScrolled .nav__registration_in:hover ~ .nav__registration_up {
  color: #fff;
}

:is(.aboutUs .nav_NoScrolled)
  .nav__registration_in:hover
  ~ .nav__registration_up {
  color: #000;
}

:is(.membership .nav_NoScrolled) .nav__registration_in:hover {
  color: #fff;
}

:is(.membership .nav_NoScrolled)
  .nav__registration_in:hover
  ~ .nav__registration_up {
  color: #000;
}

:is(.faq .nav_NoScrolled) .nav__registration_in:hover {
  color: #fff;
}

:is(.faq .nav_NoScrolled) .nav__registration_in:hover ~ .nav__registration_up {
  color: #000;
}

.nav__registration_in:hover ~ .nav__registration_up {
  color: #000;
}

.nav__registration_in:hover {
  color: #fff;
}

.nav_scrolled .nav__registration__bg {
  background: #252627;
}

:is(.zindexsht .nav_scrolled) .nav__registration__bg {
  background: #252627;
}

.nav_scrolled
  .nav__registration_in:hover
  ~ .nav_scrolled
  .nav__registration_up {
  color: #fff;
}

.nav_scrolled .nav__registration_in,
.nav_scrolled .nav__registration_up {
  color: #fff;
}

.nav_scrolled
  .nav__registration_in:hover
  ~ .nav_scrolled
  .nav__registration_up {
  color: #252627;
}

.nav_scrolled .nav__registration_in {
  color: #252627;
}

.nav_scrolled .nav__registration_in:hover {
  color: #fff;
}

.nav_scrolled .nav__registration_in:hover ~ .nav__registration_up {
  color: #252627;
}

:is(.nav_NoScrolled .nav__registration_mob) .nav__registration_in {
  color: #000;
}

:is(.zindexsht .nav__registration_mob, ) .nav__registration_up {
  color: #fff;
}

:is(.zindexsht .nav__registration_mob, ) .nav__registration__bg {
  background: #000;
}

.nav__registration_mob .nav__registration_in:hover {
  color: #fff;
}

.nav__registration_mob .nav__registration_in:hover ~ .nav__registration_up {
  color: #000;
}

.mainTop {
  position: relative;
  min-height: 631.11px;
  height: 100vh;
  z-index: 1;
  border-radius: 0px;
  background-color: #fff;
  padding: 12px;
  max-width: 1920px;
  margin: 0 auto 35px;
}

.videoMain {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: cover;
  z-index: 5;
  border-radius: 40px;
  background-color: #fff;
}

.mobileImgMain {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: cover;
  z-index: 5;
  border-radius: 40px;
  background-color: #fff;
}

.mainTop__Wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
}

.mainTop:after {
  position: absolute;
  top: 12px;
  left: 12px;
  content: "";
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  background: rgba(0, 0, 0, 0.4);
  mix-blend-mode: multiply;
  /* background-image: url(../public/img/mainBg.png);
    background-size: cover;
    filter: grayscale(100%); */
  z-index: 7;
  border-radius: 40px;
}

.mainTop::before {
  position: absolute;
  top: 12px;
  left: 12px;
  content: "";
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  background: rgba(0, 0, 0, 1);
  z-index: 7;
  mix-blend-mode: color;
  border-radius: 40px;
}

.mainTop__blue {
  position: absolute;
  bottom: -90vh;
  left: 0;
  width: 548px;
  height: 310px;
  transition: 0.4s all ease-in-out;
  padding: 40px;
  background: #4141ff;
  border-radius: 32px;
}

@media screen and (max-width: 1640px) {
  .mainTop__blue {
    left: 25px;
  }
}

@media screen and (max-width: 1560px) {
  .mainTop__blue {
    left: -12px;
  }
}

/* .mainTop__blue:hover{
    background: #4141ff;
} */
.mainTop__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 110%;
  /* or 44px */

  margin-bottom: 50px;
  color: #ffffff;
}

.mainTop__blue__bot {
  display: flex;
}

.mainTop__becomeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 48px;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  transition: 0.4s all ease-in-out;
  letter-spacing: -0.03em;
  margin-right: 25px;
  color: #131013;
  background: #ffffff;
  mix-blend-mode: normal;
  border-radius: 12px;
}

.mainTop__AboutBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 48px;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #ffffff;
  transition: 0.4s all ease-in-out;
  letter-spacing: -0.03em;
  border-radius: 12px;
}

.mainTop__becomeBtn:hover {
  color: #fff;
  background: #131313;
}

.mainTop__AboutBtn:hover {
  background: #fff;
  color: #131313;
}

.h4_secitonTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.03em;
  color: #131013;
  margin-left: 2px;
}

.section__criteria_smallTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.03em;
  color: #8a8a88;
}

.section__OurMembersGet .h4_secitonTitle {
  margin-bottom: 96px;
}

.section__OurMembersGet2 {
  display: none;

  margin-bottom: 95px;
  min-height: 100vh;
  height: 100%;
}

.section__OurMembersGet2 .ourMembersGetTitleMob {
  margin-bottom: 46px;
  margin-top: 30px;
  padding: 0 44px;
}

.headSection {
  background: #e6e6e3;
  max-width: 1920px;
  margin: 12px auto !important;
}

@media screen and (max-width: 1944px) {
  .headSection {
    max-width: calc(100vw - 24px);
    margin: 12px !important;
  }
}

.section__container {
  margin: 0 auto;
  padding: 0 69px;
}

.grey__section {
  position: relative;
  background: #e6e6e3;
  padding: 95px 0;
  width: calc(100vw - 24px);
  max-width: 1896px;
  margin: 0 auto;
}

.section__title {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.section__title__regText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.03em;
  color: #131013;
}

.textStar {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.03em;
  margin-top: 13px;
  margin-right: 5px;
  color: #131013;
}

.section__bottom__smallText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #131013;
}

.section__title__date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height */
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
}

.section__list {
  position: relative;
  width: 100%;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  margin-top: 120px;
  margin-bottom: 160px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.03em;
  z-index: 111;
  color: #131013;
}

.section__list__item {
  position: relative;
  cursor: pointer;
  transition: 0.4s all ease-in-out;
  padding-right: 5px;
  overflow: hidden;
}

.section__list__item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #131013;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
  transition: 0.4s all ease-in-out;
  /* border-radius: 50%; */
}

/* a{
    position: relative;
}
a::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #131013;
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
    transition: .4s all ease-in-out;
}
a:hover::after {
    opacity: 1;
    height: 2px;
    transform: translate3d(0, 0, 0);
}
a:focus::after {
    opacity: 1;
    height: 2px;
    transform: translate3d(0, 0, 0);
} */
.section__list__item:hover::after {
  opacity: 1;
  height: 2px;
  transform: translate3d(0, 0, 0);
}

.section__list__item:focus::after {
  opacity: 1;
  height: 2px;
  transform: translate3d(0, 0, 0);
}

.section__list__item:last-child {
  padding-right: 0;
}

.section__list__item_selected::after {
  opacity: 1;
  height: 2px;
  transform: translate3d(0, 0, 0);
}

/* .section__list__item:hover {
    text-decoration: underline;
} */

.section__list__img {
  position: absolute;
  bottom: 0px;
  right: calc(50% - 120px);

  border-radius: 40px;
  width: 240px;
  height: 304px;
  transition: all 0.3s ease-out;
  background-size: contain;
  z-index: 1;
  opacity: 0;
  transform: scale(0.4);
}

.section__list__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 40px;
}

.section__list__img_anime {
  animation: list__img_anime 0.3s linear;
}

@keyframes list__img_anime {
  0% {
    opacity: 0;
    transform: scale(0.4);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.showImg {
  transform: scale(1);
  opacity: 1;
}

.hideImg {
  opacity: 0;
  transform: scale(0.4);
}

.nav {
  position: absolute;
  top: 0;
  max-width: 1484px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
  padding: 0 25px;
  height: 88px;
  background: transparent;
  border-radius: 24px;
  z-index: 400;
  transition: transform 0.3s linear, background-color 0.3s linear;
}

@media screen and (max-width: 1000px) {
  .nav {
    height: 72px;
    margin-top: 36px;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    transition: background-color 0.3s linear !important;
  }

  .nav_flex_mob {
    margin-top: 0 !important;
  }
}

.nav_mobileBtn {
  width: 50px;
  height: 50px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #e6e6e3;
  border-radius: 25px;
}

.nav_scrolled .nav_mobileBtn {
  background-color: transparent;
}

.nav_mobileBtn svg {
  margin-bottom: -5px;
}

.nav_scrolled {
  background: rgba(230, 230, 227, 0.9);
  /* box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08); */
  position: fixed;
  transform: translateY(0%);
  opacity: 1;
}

@media screen and (max-width: 660px) {
  .nav_scrolled {
    margin-top: 12px !important;
  }
}

.is-at-top {
  background: transparent;
  position: fixed;
}

.nav_hidden {
  transform: translateY(-150%);
  background: transparent;
  opacity: 0;
}

.nav__nav {
  display: flex;
  align-items: center;
  transform: translateX(0%);
}

.nav__nav_mob {
  display: none;
  flex-direction: column;
}

.nav__nav_mob__opacity {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  mix-blend-mode: darken;
  z-index: 254;
  transition: opacity 0.3s ease-in-out;
}

.nav__nav_mob__opacity_closed {
  opacity: 0;
  background: rgba(0, 0, 0, 0);
  pointer-events: none;
}

.nav__logo {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 22.377px;
  line-height: 27px;
  /* identical to box height */

  letter-spacing: -0.02em;
  cursor: pointer;
  color: #131013;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 440px) {
  .nav__logo {
    max-width: 160px;
  }

  .nav__logo svg {
    max-width: 160px;
  }
}

.nav__nav__link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* identical to box height */
  cursor: pointer;
  letter-spacing: -0.03em;
  color: #131013;
  margin-right: 35px;
  transition: 0.2s all linear;
}

.nav__nav__link_mob {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* identical to box height */
  cursor: pointer;
  letter-spacing: -0.03em;
  color: #131013;
  margin-right: 35px;
  transition: 0.2s all linear;
  padding-bottom: 20px;
  margin-bottom: 5px;
}

.nav__nav__link:hover {
  transform: scale(1.08);
}

.nav_NoScrolled .nav__nav__link,
.nav_NoScrolled .nav__logo {
  color: #fff;
}

:is(.nav_mob_opened) .nav__logo {
  color: #252627;
}

:is(.aboutUs .nav_NoScrolled) .nav__logo {
  color: #252627;
}

:is(.aboutUs .nav_NoScrolled) .nav__nav__link {
  color: #252627;
}

:is(.faq .nav_NoScrolled) .nav__logo {
  color: #252627;
}

:is(.faq .nav_NoScrolled) .nav__nav__link {
  color: #252627;
}

:is(.membership .nav_NoScrolled) .nav__logo {
  color: #252627;
}

:is(.membership .nav_NoScrolled) .nav__nav__link {
  color: #252627;
}

.nav_scrolled .nav__nav__link,
.nav_scrolled .nav__logo {
  color: #252627;
}

.nav__nav__link:last-child {
  margin-right: 0;
}

@keyframes anime {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes anime2 {
  from {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(1, 1, 1);
  }
}

.zindexsht {
  position: relative;
  z-index: 22;
  width: 100%;
  height: 100%;

  animation: anime 1.1s ease-in-out;
  max-width: 100vw;
  overflow: hidden;
}

.aboutUs {
  animation: anime 1.1s ease-in-out;
}

.membership {
  animation: anime2 1.1s ease-in-out;
}

.faq {
  animation: anime2 1.1s ease-in-out;
}

.abs {
  position: absolute;
  width: 100%;

  top: 0;
  left: 0;
}

.modalWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}

.modalWrapper_closed {
  background-color: rgba(0, 0, 0, 0);
  z-index: 0;
}

.modalWrapper_open {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 200;
}

.modalBlock {
  position: absolute;
  top: 50%;
  right: -100%;
  transform: translate(0%, -50%);
  width: 512px;
  height: calc(100vh - 24px);
  /* White */
  background: #ffffff;
  /* Shadow */
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.16);
  border-radius: 40px;
  z-index: 250;
  transition: all 0.4s ease-out;
  overflow-x: hidden;
}

.modalBlock::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
.modalBlock::-webkit-scrollbar-thumb {
  background: #e6e6e3;
}

.modalBlock_closed {
  opacity: 0;
  right: -100%;
}

.modalBlock_open {
  opacity: 1;
  right: 1%;
}

.modalBlock__content {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
}

.modalBlock__content:after {
  position: sticky;
  bottom: 0;
  left: 0;
  margin-left: -50px;
  content: "";
  width: calc(100% + 110px);
  height: 80px;
  background: transparent;
  z-index: 2;
}

.modalBlock__content__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalBlock__content__tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 24px;
}

.tag {
  background: #e6e6e3;
  mix-blend-mode: normal;
  border-radius: 12px;
  padding: 8px 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  margin-bottom: 8px;
  letter-spacing: -0.03em;
  margin-right: 8px;
  color: #131013;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.tag:last-child {
  margin-right: 0;
}

.tag:hover {
  background: #131313;
  color: #fff;
}

.modalBlock__content__head__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 100% */
  max-width: 80%;
  letter-spacing: -0.03em;

  color: #131013;
}

.closeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  /* Light gray */
  border-radius: 50%;
  transition: all ease-out 280ms;
  background: inherit;
}

.closeBtn:hover {
  background: #e6e6e3;
}

:is(.nav_mob_opened) .closeBtn {
  background: #252627;
}

.close-button {
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}

.close-button > div {
  position: relative;
}

.close-button-block {
  width: 40px;
  height: 20px;
  position: relative;
  overflow: hidden;
}

.close-button-block:before,
.close-button-block:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(50% - 1px);
  display: block;
  width: 2px;
  height: 17px;
  transform-origin: bottom center;
  border-radius: 3px;
  background: #252627;
  transition: all ease-out 280ms;
}

.nav_mob_opened .close-button-block:before,
.nav_mob_opened .close-button-block:after {
  background: #fff;
}

.close-button-block:last-of-type {
  transform: rotate(180deg);
}

.close-button .in .close-button-block:before {
  transition-delay: 210ms;
  transform: translateX(20px) translateY(-20px) rotate(45deg);
}

.close-button .in .close-button-block:after {
  transition-delay: 210ms;
  transform: translateX(-22px) translateY(-22px) rotate(-45deg);
}

.close-button .out {
  position: absolute;
  top: 0;
  left: 0;
}

.close-button .out .close-button-block:before {
  transform: translateX(-5px) translateY(5px) rotate(45deg);
}

.close-button .out .close-button-block:after {
  transform: translateX(5px) translateY(5px) rotate(-45deg);
}

.close-button:hover .in .close-button-block:before {
  transform: translateX(-5px) translateY(5px) rotate(45deg);
}

.close-button:hover .in .close-button-block:after {
  transform: translateX(5px) translateY(5px) rotate(-45deg);
}

.close-button:hover .out .close-button-block:before {
  transform: translateX(-20px) translateY(20px) rotate(45deg);
}

.close-button:hover .out .close-button-block:after {
  transform: translateX(20px) translateY(20px) rotate(-45deg);
}

.modalBlock__content__wrapper {
  width: 100%;
  padding-right: 64px;
}

.modalBlock__content__location {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

.modalBlock__content__location__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */
  padding-left: 8px;
  letter-spacing: -0.03em;

  color: #252627;
}

.modalBlock__content__description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* or 138% */
  margin-bottom: 24px;
  letter-spacing: -0.03em;

  color: #252627;
}

.modalBlock__content__date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;

  color: #252627;
  margin-bottom: 24px;
}

.modalBlock__content__logo {
  width: 100%;
  height: 162px;
  margin-bottom: 24px;
  border: 1px solid #e6e6e3;
  background: #ffffff;
  mix-blend-mode: normal;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modalBlock__content__logo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.modal__bottom {
  width: 100%;
}

.btn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* identical to box height, or 143% */
  /* Black */
  mix-blend-mode: normal;
  border-radius: 12px;
  color: #252627;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnModalGrey {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  padding: 18px 40px;
  display: flex;
  justify-content: center;
  background: #ffffff;
  transition: all 0.4s ease-out;
  z-index: 3;
}

.btnModalGrey:hover {
  background: #e6e6e3;
}

.errorPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.errorPage h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 15px;
  color: #252627;
}

.errorBtn {
  background: #252627;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 12px;
  padding: 18px 40px;
  color: #fff;
}

.step {
  transition: all 0.4s ease-out;
}

.guys {
  display: flex;
  justify-content: space-between;
  margin-top: 112px;
  margin-bottom: 84px;
}

.guysPhotos {
  display: flex;
  width: 50%;
  margin-left: 80px;
}

.guysPhotos__circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background: #e6e6e3;
  border-radius: 100%;
  margin-left: -24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */

  letter-spacing: -0.05em;

  color: #131013;
}

.guysPhotos__circle:first-child {
  margin-left: 0;
  outline: 5px solid #ffffff;
}

.guysPhotos__circle:last-child {
  outline: 5px solid #ffffff;
}

.guysPhotosText {
  width: 53%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;

  /* Gray 1 */
  margin-right: 30px;
  color: #333333;
}

.becomeMember {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 467px;
  margin: 0 auto;
  margin-bottom: 161px;
  padding-left: 40px;
}

.becomeMember__text {
  margin-top: 31px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.02em;
  margin-bottom: 55px;
  /* Gray 1 */

  color: #333333;
}

.becomeMember__btn {
  background: #131013;
  mix-blend-mode: normal;
  border-radius: 12px;
  padding: 16px 68px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;
  width: fit-content;
  color: #ffffff;
  transition: all 0.2s linear;
  border: 2px solid #131013;
}

.becomeMember__btn:hover {
  background: #fff;
  border: 2px solid #131013;
  color: #000;
}

.getStrd__btn {
  background: #ffffff;
  mix-blend-mode: normal;
  border-radius: 12px;
  padding: 16px 96px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;

  color: #131013;
  width: fit-content;

  transition: all 0.2s linear;
}

.getStrd__btn:hover {
  background: #131013;
  /* outline: 2px solid #fff; */
  color: #fff;
}

.bigWhyUs {
  margin: 0 auto;
  width: 950px;
  height: 236px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6e6e3;
  border-radius: 120px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  line-height: 120%;
  /* or 67px */

  letter-spacing: -0.02em;

  color: #131013;
  margin-bottom: 158px;
}

.bigWhyUs div {
  margin-left: 5px;
  padding-top: 8px;
}

.offerSection__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
}

.offer__title {
  width: 447.75px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  /* or 29px */

  text-align: center;
  letter-spacing: -0.03em;
  margin-bottom: 61px;
  color: #131013;
}

.offerBlock {
  width: 100%;
  /* min-height: 744px; */
  height: 100%;
  background: #131313;
  border-radius: 40px;
  margin-top: 94px;
  margin-bottom: 73px;
}

.offerBlockWrap {
  width: 100%;
  max-width: 1420px;
  margin: 0 auto;
  padding: 80px 68px 60px;
}

.arrowDown {
  transform: rotate(180deg);
}

.offerBlock__topRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.offerBlock__topRow__left {
  width: 55.5%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #ffffff;
}

.offerBlock__topRow__right {
  width: 53%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 120%;
  /* or 48px */
  min-height: 215px;
  height: 100%;
  letter-spacing: -0.03em;

  color: #ffffff;
}

.offerBlock__bottomRow {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.offerBlock__bottomRow__left {
  width: 53%;
  margin-bottom: 26px;
}

.offerBlock__bottomRow__left__block {
  width: 403px;
  min-height: 151px;
  height: 100%;
  padding: 15px 26px 35px 28px;
  background: #979792;
  border-radius: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.03em;
  color: #131013;
  cursor: pointer;
  transition: 0.3s all linear;
}

.offerBlock__bottomRow__left__block:hover {
  background: #fff;
}

.offerBlock__bottomRow__left__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 23px;
}

.offerBlock__bottomRow__left__text {
  font-size: 24px;
  width: 287.5px;
}

.offerBlock__bottomRow__right {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}

.offerBlock__bottomRow__right__block {
  margin-right: 78px;
  margin-bottom: 84px;
  width: 221px;
  min-height: 57px;
  height: 100%;
}

.offerBlock__bottomRow__right__block__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  margin-bottom: 13px;
  letter-spacing: -0.03em;

  color: #ffffff;
}

.offerBlock__bottomRow__right__block__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;

  color: #8a8a88;
}

.howToJ {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #131013;
  margin: 0 auto;
}

.stepStep {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #a2a2a2;
  margin: 0 auto;
  margin-bottom: 80px;
}

.steps {
  width: 630px;
  margin: 0 auto;
}

.step__block {
  min-height: 244px;
  height: 100%;
  width: 100%;
  display: flex;
  padding: 36px 45px 42px 45px;
  margin-bottom: 40px;
  background: #ffffff;
  border-radius: 32px;
}

.step__block:last-child {
  margin-bottom: 0;
}

.step__block_dealFlow {
  display: flex;
  justify-content: space-between;
  min-height: 244px;
  height: 100%;
  width: 100%;
  display: flex;
  padding: 20px 20px 20px 40px;
  margin-bottom: 40px;
  background: #131013;
  border-radius: 32px;
}

.step__block_dealFlow:last-child {
  margin-bottom: 0;
}

.step__left {
  width: 240px;
}

.step__right {
  width: 240px;
}

.step__block__count {
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #0f0f0f;
  background: #e6e6e3;

  margin-right: 94px;
}

.step__block_dealFlow__count {
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.03em;
  color: #fff;
  background: #333333;
  margin-right: 94px;
  margin-top: 20px;
  margin-bottom: 26px;
}

.step__block__text__deaflow {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.035em;
  color: #ffffff;
  margin-bottom: 26px;
}

.step__left__descr__deaflow {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #8a8a88;
  margin-bottom: 20px;
}

.step__left__descr__deaflow span {
  color: #fff;
  cursor: pointer;
}

.step__right__block__count {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.035em;
  color: #ffffff;
}

.step__right__block__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #8a8a88;
  margin-bottom: 51px;
}

.step__right__block {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #272727;
  border-radius: 20px;
}

.step__block__text {
  display: flex;
  flex-direction: column;
}

.step__block__text__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  letter-spacing: -0.03em;
  margin-bottom: 20px;
  color: #131013;
}

.step__block__text p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;
  margin-bottom: 25px;
  color: #606060;
  width: 288px;
}

.step__block__text p:last-child {
  margin-bottom: 0;
}

.step__block__text span {
  font-weight: 600;
  color: #131013;
}

.displayFlexBlock {
  width: 100%;
  display: flex;
  align-items: center;
}

.displayFlexBlockCriteria {
  justify-content: space-between;
}

.sectionWisinc {
  padding: 0 69px;
  padding-bottom: 134px;
}

.secTitle {
  margin-top: 116px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */

  letter-spacing: -0.03em;
  margin-bottom: 51px;
  color: #131013;
}

.wisinc {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.wisinc__left {
  width: 50%;
  padding-top: 10px;
}

.wisinc__right {
  overflow: hidden;
  width: 50%;
  margin-left: 135px;
  padding-left: 47px;
  padding-top: 46px;
  width: 470px;
  height: 516px;

  background: #e6e6e3;
  border-radius: 25.0582px;
}

.wisinc__left__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 54px;
  color: #131013;
  width: 565px;
}

.wisinc__left__flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.wisinc__left__flex__block {
  margin-bottom: 63px;
  max-width: 241px;
  width: 100%;
}

.wisinc__left__flex__block__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  letter-spacing: -0.03em;
  margin-bottom: 13px;
  color: #131013;
}

.wisinc__left__flex__block__text {
  min-height: 57px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;

  /* Gray 3 */

  color: #828282;
}

.footer {
  width: 100%;
  background: linear-gradient(68.38deg, #4141f5 29.21%, #5570ff 103.92%);
  border-radius: 40px;
  padding: 121px 12px 12px 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  max-width: 1920px;
  margin: 0 auto !important;
}

.footer__content {
  display: flex;
  margin: 0 auto;
  margin-bottom: 142px;
  width: fit-content;
}

.footer__content__left {
  width: 322px;
  margin-right: 263px;
}

.footer__content__left__title {
  position: relative;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 100%;
  /* or 56px */
  display: flex;
  letter-spacing: -0.02em;

  color: #ffffff;
  margin-bottom: 36px;
}

.footer__attention {
  width: 134px;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 9px;
  right: 60px;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-left: 8px;
  margin-left: 15px;
  color: #ffffff;
}

.footer__right__container {
  position: relative;
  width: 376px;
  min-height: 200px;
  height: 100%;
}

.footer__right {
  position: relative;

  bottom: -60px;
  left: -58px;

  width: 376px;
  min-height: 200px;
  background: #ffffff;
  border-radius: 20px;
  padding: 29px 0;
  z-index: 30;
}

.footer__right__opaciti1 {
  position: absolute;

  bottom: -60px;
  left: -58px;

  width: 100%;
  height: 100%;
  background: #6788ff;
  border-radius: 20px;
  z-index: 20;
}

.footer__right__opaciti2 {
  position: absolute;
  bottom: -60px;
  left: -58px;
  width: 100%;
  height: 100%;
  background: #4d5eff;
  border-radius: 20px;
  z-index: 10;
}

.fAnimeBlock {
  transition: all 0.4s ease-in-out;
}

.fAnimeBlock_on0 {
  bottom: 0px;
  left: 0px;
}

.fAnimeBlock_on1 {
  bottom: -30px;
  left: -30px;
}

.fAnimeBlock_on2 {
  cursor: pointer;
}

.footer__right__head {
  padding: 0 29px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 51px;
}

.footer__right__head__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;

  color: #131013;
}

.footer__right__live {
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  letter-spacing: -0.03em;
  background: #3830fb;
  border-radius: 6px;
  color: #ffffff;
  padding: 4px 6px;
}

.footer__right__live div {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #ffffff;
  margin-right: 4px;
  margin-bottom: 1px;
}

.footer__right__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  letter-spacing: -0.05em;
  padding-left: 22px;
  padding-right: 9px;
  color: #131013;
}

.footer__black {
  position: relative;
  width: 100%;
  padding-top: 70px;
  padding-bottom: 56px;
  padding-left: 68px;
  padding-right: 68px;
  background: #131313;
  border-radius: 40px;
  /* min-height: 450px;
    height: 100%; */
}

.footer__black__flex {
  display: flex;
}

.footer__black__flex__logo {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  margin-right: 144px;
  letter-spacing: -0.02em;

  color: #ffffff;
}

.footer__black__social {
  margin-top: 66px;
  display: flex;
  width: 105px;
  justify-content: space-between;
}

.footer__black__flex__col {
  margin-right: 106px;
}

.footer__black__flex__col__li {
  position: relative;
  margin-bottom: 12px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  color: #ffffff;
  overflow: hidden;
  transition: 0.4s all ease-in-out;
  width: fit-content;
  padding-bottom: 2px;
}

.footer__black__flex__col__li::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
  transition: 0.4s all ease-in-out;
  /* border-radius: 50%; */
}

.footer__black__flex__col__li:hover::after {
  opacity: 1;
  height: 2px;
  transform: translate3d(0, 0, 0);
}

.greyLinks {
  color: #a2a2a2;
}

.greyLinks:hover {
  color: #fff;
}

.footer__black__social a path {
  transition: 0.3s all ease-in-out;
}

.footer__black__social a:hover path {
  fill: #7676df;
}

.footer__rights {
  position: absolute;
  bottom: 68px;
  left: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #8a8a88;
}

.speaky {
  position: absolute;
  width: 376px;
  height: 346px;
  right: 0;
  /* bottom: 60px; */
  padding: 32px;
  background: transparent;
  border-radius: 20px;
  background: #1b1a1b;
}

.speaky-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.speaky__head {
  position: relative;
  height: 56px;
  margin-bottom: 24px;
}

.speaky__head_img_first {
  position: absolute;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 2.62049px solid #212121;
  left: 0;
  top: 0;
}

.speaky__head_img_second {
  position: absolute;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 2.62049px solid #212121;
  left: 44px;
  top: 0;
}

.speaky__head_img_third {
  position: absolute;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 2.62049px solid #212121;
  left: 88px;
  top: 0;
}

.speaky__head__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  align-items: center;
  /* or 19px */
  padding-left: 5px;

  color: #ffffff;
}

.speaky__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  /* or 31px */
  color: #ffffff;
  /* cursor: pointer; */
  transition: all 0.3s linear;
  position: relative;
  width: fit-content;
}

/* .speaky__text:hover {
  color: #4141F5;
} */

/* .speaky__text::after {
  content: '';
  background-image: url('../public/img/arrowRightTop.svg');
  transform: translate(100%, -50%);
  right: -4px;
  position: absolute;
  opacity: 0;
  transition: all .3s linear;
  width: 32px;
  height: 32px;
  top: 50%;
} */

/* .speaky__text:hover::after {
  opacity: 1;
} */

.speakyBtn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background: #252627;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;

  height: 56px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  transition: all 0.3s ease-out;
  text-decoration: none !important;
}

.speaky__bottom {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #969696;

  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
}

.speakyBtn:hover {
  background: #ffffff;
  color: #252627;
}

.speaky__bottom span {
  color: #ffffff;
  margin-left: 2px;
}

.text__bg {
  padding: 3px 20px;
  padding-right: 10px;
  background: #e6e6e3;
  border-radius: 30px;
  margin: 0 -8px;
  margin-left: -20px;
}

.wwo_bg {
  background: #5c5c5c;
  mix-blend-mode: normal;
  border-radius: 24px;
  padding: 1px 8px;
  white-space: nowrap;
}

.text_bgInvst {
  white-space: nowrap;
  background: #e6e6e3;
  mix-blend-mode: normal;
  border-radius: 24px;
  padding: 1px 8px;
}

.section__OurMembersGet {
  padding: 49px 0 136px 0px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

@media (max-width: 888px) {
  .membship_steps .steps {
    width: 70vw;
    margin: 0 auto;
  }

  .offerSection__container .step__block {
    min-height: 210px;
    padding: 36px 30px 42px 40px;
  }

  .step__block__count {
    margin-right: 50px;
  }

  /* 
    .section__OurMembersGet {
        display: none;
    } */

  .section__OurMembersGet2 {
    display: block;
  }

  .nav__nav__link_mob {
    width: fit-content;
  }

  .guysPhotos {
    margin: 0;
    margin-bottom: 80px;
    justify-content: flex-start;
    width: 100vw;
  }

  /* .guysPhotos {
        margin: 0;
        margin-bottom: 80px;
        width: 100%;
        display: flex;
        justify-content: center;
    } */

  .guysPhotosText {
    width: 100%;
  }

  .section__OurMembersGet2 {
    min-height: unset;
  }
}

.timeLine {
  position: relative;
  z-index: 1;
  height: 100vh;
  /* margin-top: 500px; */
}

.timeLineFull {
  /* width: 100%;
    display: flex;
    overflow-x: space;
    flex-wrap: nowrap;
    transition: all 2.4s linear;
    padding: 0 57px;
    overscroll-behavior: contain; */
  display: flex;
  position: relative;
  z-index: 1;
  height: 100vh;
  padding-left: 38px;
  padding-right: 500px;
}

.timeLine__item {
  /* width: 360px; */
  max-width: 360px;
  min-width: 360px;
  height: 504px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(214, 213, 208, 0.6);
  border-radius: 40px;
  position: relative;
  align-items: flex-start !important;
  text-align: start;
}

.timeLine__item__img {
  margin: 64px 0;
}

@media screen and (max-width: 900px) {
  .timeLine__item__img {
    margin: 40px 0;
  }
}

.timeLineFull.to-right {
  counter-reset: timeLine__item;
  float: left;
}

.timeLine__item__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.05em;
  min-height: 75px;
  color: #131013;
}

@media screen and (max-width: 450px) {
  .timeLine__item__title {
    font-size: 28px;
    line-height: 32px;
  }
}

.timeLine__item__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.05em;
  min-height: 80px;
  color: #131013;
}

.secAboutUs {
  display: flex;
  width: 100%;
  padding: 0 96px;
  margin-bottom: 106px;
  /* padding-right: 0; */
  padding-bottom: 124px;
}

.secAboutUsWrap {
  display: flex;
  flex-direction: column;
}

.secAboutUsContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 96px;
}

.secUpcomingEvents {
  display: flex;
  width: 100%;
  padding: 96px 0 124px 0;
}

@media screen and (max-width: 660px) {
  .secUpcomingEvents {
    display: flex;
    width: 100%;
    padding: 96px 0 124px 0;
    margin-bottom: 106px;
  }
}

.secAboutUs__left {
  width: 36.4%;
}

.secAboutUs__right {
  width: 49.2%;
}

.secAboutUs__left__text {
  /* margin-top: 81px;   */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.05em;
  color: #000;
  /* width: 433px; */
}

.secAboutUs__left__text p {
  margin-bottom: 25px;
}

.secAboutUs__left__text span {
  color: #3030fb;
}

.secAboutUs__left__text a {
  color: #000;
  text-decoration: underline;
}

.secAboutUs__right__trac {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e6e6e3;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18.5113px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.03em;
  padding: 0 11px;
  color: #131013;
  width: fit-content;
  padding-left: 10px;
  padding-right: 23px;
  height: 36px;
}

.secAboutUs__right__trac svg {
  margin-right: 8px;
}

.secAboutUs__right__flex {
  display: flex;
  flex-wrap: wrap;
  margin-top: 72px;
}

.secAboutUs__right__item {
  /* margin-right: 105px; */
  width: 33.33%;
  margin-bottom: 42px;
}

.secAboutUs__right__item:nth-child(2) {
  margin-left: 30px;
  width: calc(33.33% - 30px);
}

.secAboutUs__right__item__count {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  line-height: 68px;
  /* identical to box height */

  letter-spacing: -0.05em;
  margin-bottom: 3px;
  color: #131013;
}

.secAboutUs__right__item__desrc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  display: flex;
  letter-spacing: -0.05em;

  color: #131013;
}

.section__slider {
  padding: 0 70px;
  padding-top: 96px;
  padding-bottom: 96px;
  background: #131013;
  border-radius: 40px;
  z-index: 2;
  max-width: 1896px;
  width: calc(100vw - 24px);
  margin: 0 auto 20px auto;
}

.slider__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height */
  margin-bottom: 96px;
  letter-spacing: -0.03em;

  color: #8f8c8c;
}

.section__slider h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #ffffff;
}

.ourMembersGetTitleMob {
  display: flex;
  justify-content: space-between;
  max-width: 1484px;
  margin-left: auto;
  margin-right: auto;
}

.sliderWrap__OurMembersGet {
  width: 1496px;
  margin: 0 auto;
  transform: translateX(6px);
}

.SampleNextArrow {
  position: absolute;
  top: -74px;
  transform: translateY(-100%);
  right: 12px;
}

.SamplePrevArrow {
  position: absolute;
  top: -74px;
  transform: translateY(-100%);
  right: 62px;
}

.sectionWrap__OurMembersGet {
  max-width: unset;
}

@media screen and (max-width: 1648px) {
  .ourMembersGetTitleMob {
    margin-left: 82px;
  }

  .sliderWrap__OurMembersGet {
    width: 1488px;
    transform: unset;
    margin-left: 82px;
  }

  .SampleNextArrow {
    left: calc(100vw - 214px);
    right: unset;
  }

  .SamplePrevArrow {
    left: calc(100vw - 276px);
    right: unset;
  }
}

@media (max-width: 1410px) {
  .mainTop__blue {
    width: 490px;
    height: 270px;
    bottom: -88vh;
  }

  .mainTop__title {
    font-size: 32px;
  }

  .secAboutUs__right__item {
    /* margin-right: 0; */

    width: 50%;
  }

  .secAboutUs__right__item:nth-child(2) {
    margin-left: 0;
    width: 50%;
  }
}

@media (max-width: 1340px) {
  .mainTop__blue {
    left: 58px;
  }
}

.slider__wraper {
  width: 100%;
  position: relative;
}

.events_slider_wrapper::after {
  content: "";
  position: absolute;
  right: 0;
  background: linear-gradient(
    270deg,
    #ffffff 0%,
    #ffffff 0.01%,
    rgba(255, 255, 255, 0) 100%
  );
  top: 0;
  width: 360px;
  height: 100%;
  z-index: 1;
}

@media screen and (max-width: 780px) {
  .slider__wraper::after {
    display: none;
  }
}

.s__upcomingEvents .slider__wraper {
  width: 107%;
}

.slider__nav {
  display: flex;
}

/* .ourMembersGetTitleMob .slider__nav {
    display: none;
} */

.Slider_navBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #3d3b3d;
  border-radius: 25px;
}

.section__OurMembersGet .Slider_navBtn {
  background: rgb(245 245 244);
}

.section__OurMembersGet .Slider_navBtn path {
  transition: 0.3s all ease-in-out;
}

.s__upcomingEvents .Slider_navBtn {
  background: rgb(245 245 244);
}

.s__upcomingEvents .Slider_navBtn path {
  transition: 0.3s all ease-in-out;
}

.SliderLeft {
  position: relative;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
  margin-right: 12px;
  overflow: hidden;
  z-index: 20;
}

.SliderRight {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  z-index: 20;
  background: #c5c5c2;
  transition: 0.5s all ease-in-out;
}

.section__OurMembersGet .SliderRight {
  background: #e6e6e3;
}

.s__upcomingEvents .SliderRight {
  background: #e6e6e3;
}

.slider__top {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.SliderLeft:hover ~ .SliderRight {
  background: #3d3b3d;
}

.SliderLeft:hover {
  background: #c5c5c2;
}

.section__OurMembersGet .SliderLeft path {
  fill: rgb(153 153 153);
}

.section__OurMembersGet .SliderLeft:hover ~ .SliderRight {
  background: rgb(245 245 244);
}

.section__OurMembersGet .SliderLeft:hover ~ .SliderRight path {
  fill: rgb(153 153 153);
}

.section__OurMembersGet .SliderLeft:hover {
  background: #e6e6e3;
}

.section__OurMembersGet .SliderLeft:hover path {
  fill: #000;
}

.s__upcomingEvents .SliderLeft path {
  fill: rgb(153 153 153);
}

.s__upcomingEvents .SliderLeft:hover ~ .SliderRight {
  background: rgb(245 245 244);
}

.s__upcomingEvents .SliderLeft:hover ~ .SliderRight path {
  fill: rgb(153 153 153);
}

.s__upcomingEvents .SliderLeft:hover {
  background: #e6e6e3;
}

.s__upcomingEvents .SliderLeft:hover path {
  fill: #000;
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

.section__criteria {
  padding: 116px 96px;
}

@media (max-width: 700px) {
  .mainTop__blue {
    width: 83vw;
    height: 270px;
    bottom: -93vh;
  }

  .slider__top {
    flex-direction: column;
  }
}

@media screen and (max-width: 660px) {
  .ourMembersGetTitleMob {
    margin-left: 44px;
  }

  .sliderWrap__OurMembersGet {
    width: 1488px;
    transform: unset;
    margin-left: 44px;
  }

  .SampleNextArrow {
    bottom: -48px;
    top: unset;
    transform: translateY(100%);
    left: 62px;
    right: unset;
  }

  .SamplePrevArrow {
    bottom: -48px;
    top: unset;
    transform: translateY(100%);
    left: 0;
    right: unset;
  }

  .mainTop__blue {
    left: 32px;
    padding: 30px;
    width: calc(100vw - 94px);
    height: fit-content;
    bottom: -93vh;
    /* border-radius: 40px; */
  }
}

@media screen and (max-width: 660px) {
  .section__criteria {
    padding-right: 44px;
    padding-left: 44px;
  }
}

.section__criteria__left {
  width: 54%;
  margin-top: -70px;
}

.section__criteria__left__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.03em;
  width: 468px;
  color: #131013;
  margin-bottom: 110px;
  margin-top: 96px;
}

.section__criteria__left__text span {
  white-space: nowrap;
  padding: 0px 8px;
  padding-right: 16px;
  background: #e6e6e3;
  border-radius: 24px;
  margin: 0 6px;
}

.section__criteria__left__text span svg {
  margin-left: 13px;
  margin-bottom: -3px;
}

.section__criteria__fEnd {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 17px;
}

.section__criteria__fEnd__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */

  width: 269px;
  color: #131013;
  margin-top: 30px;
}

.section__criteria__btn {
  margin-top: -62px;
  width: fit-content;
  display: block;
  border: 1px solid #131013;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;
  padding: 14.5px 21px;
  color: #131013;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  text-decoration: none;
}

.section__criteria__btn:hover {
  background-color: #131013;
  color: #fff;
}

.section__criteria__right {
  margin-left: 99px;
}

.section__criteria__right__item {
  width: 520px;
  padding: 34px 0;
  padding-left: 28px;
  padding-right: 42px;
  display: flex;
  margin-bottom: 14px;
  border: 1px solid #e6e6e3;
  border-radius: 32px;
  display: flex;
  align-items: center;
}

.section__criteria__right__item__circle {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #e6e6e3;
  border-radius: 100%;
}

.section__criteria__right__item span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  max-width: 388px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  padding-left: 28px;
  color: #252627;
}

.section__title__date_low {
  text-transform: none;
}

.lds-ellipsis {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-right: 45%;

  position: relative;
  width: fit-content;
  height: 50%;
}

.lds-ellipsis_small {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}

.lds-ellipsis div {
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #252627;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis_small div {
  position: absolute;
  border-radius: 50%;
  background: #252627;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  top: calc(50% - 4px);
  width: 8px;
  height: 8px;
}

.modal_loader {
  position: relative;
  width: 50%;
  height: 100%;
}

.lds-ellipsis_white div {
  background: #fff;
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.9s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.9s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.9s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.9s infinite;
}

.lds-ellipsis_small div:nth-child(1) {
  left: 7px;
  animation: lds-ellipsis1_sm 0.9s infinite;
}

.lds-ellipsis_small div:nth-child(2) {
  left: 7px;
  animation: lds-ellipsis2_sm 0.9s infinite;
}

.lds-ellipsis_small div:nth-child(3) {
  left: 18px;
  animation: lds-ellipsis2_sm 0.9s infinite;
}

.lds-ellipsis_small div:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3_sm 0.9s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

@keyframes lds-ellipsis1_sm {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3_sm {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2_sm {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(14px, 0);
  }
}

.dealFlow__left {
  margin-right: 15px;
  position: relative;
}

.dealFlow__left__item {
  position: relative;
  z-index: 20;
  width: 251px;
  height: 124.75px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  margin-bottom: 36px;
}

#ids_mob {
  position: absolute;
  bottom: -57px;
  left: 0;
  /* width: 10px;
    height: 10px;
    background: #000; */
}

#psd_mob {
  position: absolute;
  bottom: 70px;
  left: 0;
  /* width: 10px;
    height: 10px;
    background: #000; */
}

@media (max-height: 704px) {
  #ids_mob {
    bottom: -80px;
  }
}

.dealFlow__left__item__hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 251px;
  height: 124.75px;
  z-index: 2;
  background-color: #fff;
  transition: 0.5s all ease-in-out;
  border-radius: 32px;
}

.dealFlow__left__item__hover_0 {
  transform: translateY(0%);
}

.dealFlow__left__item__hover_1 {
  transform: translateY(130%);
}

.dealFlow__left__item__hover_2 {
  transform: translateY(260%);
}

.dealFlow__left__count {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 110%;
  /* identical to box height, or 62px */
  transition: 0.4s all ease-in-out;
  letter-spacing: -0.035em;

  color: #131013;
  margin-bottom: 2px;
}

#ids {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 10px;
  height: 10px;
}

.dealFlow__left__descr {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  transition: 0.4s all ease-in-out;
  color: #131013;
}

.dealFlow__right {
  width: 488px;
  min-height: 454.5px;
  height: 100%;
  background: #ffffff;
  border-radius: 32px;
  padding: 30px 46px 30px 46px;
}

.dealFlow__right__count {
  width: 89px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 21px;
  border: 1px solid #e6e6e3;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18.5113px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.03em;

  color: #131013;
}

.dealFlow__right__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 110%;
  /* identical to box height, or 44px */

  letter-spacing: -0.035em;
  margin-bottom: 28px;
  color: #131013;
}

.dealFlow__right__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */
  width: 303.89px;
  letter-spacing: -0.01em;
  color: #828282;
}

.dealFlow__right__text span {
  color: #000;
  text-decoration: underline;
}

.s__upcomingEvents {
  /* padding-top: 116px; */
  /* padding-bottom: 116px; */
}

.s__upcomingEvents_Btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 164px;
  height: 48px;
  padding: 0;
  transition: 0.3s all ease;
}

.s__upcomingEvents_Btn svg {
  margin-left: 4px;
  transition: 0.3s all ease;
}

.s__upcomingEvents_Btn:hover {
  padding-left: 30px;
}

.s__upcomingEvents__head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* padding: 0 10px; */
  margin-bottom: 96px;
}

.timeLineEvents {
  width: inherit;
  overflow-x: hidden;
}

.timeLineFullEvents {
  width: 100%;
  display: flex;
  overflow-x: space;
  flex-wrap: nowrap;
  transition: all 3.4s ease-in-out;
  padding: 15px 57px;
  overscroll-behavior: contain;
}

.timeLineEvents__item {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 336px;
  height: 404px;
  padding: 32px;
  background: #ffffff;
  border: 1px solid #e6e6e3;
  overflow: hidden;
  border-radius: 32px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.timeLineEvents__item:hover {
  /* box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1); */
  background: #e6e6e3;
}

.timeLineEvents__item__head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
}

.timeLineEvents__item__head__time__date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #131013;
}

.timeLineEvents__item__head__time__hours {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #9c9c9c;
}

.timeLineEvents__item__head__status {
  padding: 2px 8px;
  border: 1px solid #e6e6e3;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.03em;
  min-width: 61px;
  height: 23px;
  color: #131013;
  display: flex;
  align-items: center;
  border-radius: 24px;
}

.timeLineEvents__item__type {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #3030fb;
  margin-bottom: 8px;
  text-align: left;
}

.timeLineEvents__item__descr {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #131013;
  min-height: 125px;
  max-height: 125px;
}

.timeLineEvents__item__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: calc(100% - 64px);
  width: 100%;
  height: 88px;
  background: #e6e6e3;
  border-radius: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: -0.03em;
  color: #131013;
  transition: all 0.3s ease-in-out;
  position: absolute;
  bottom: 32px;
  left: 32px;
  opacity: 0;
  z-index: 2;
}

.timeLineEvents__item:hover .timeLineEvents__item__btn {
  opacity: 1;
}

.timeLineEvents__item__btn path {
  margin-left: 15px;
  transition: all 0.3s ease-in-out;
  stroke: #131013;
}

.timeLineEvents__item__btn:hover {
  background: #131013;
  color: #e6e6e3;
}

.timeLineEvents__item__btn:hover path {
  stroke: #fff;
}

.timeLineEvents__item__img {
  opacity: 1;
  transition: all 0.1s linear;
  border-radius: 16px;
  width: 176px;
  height: 88px;
  position: absolute;
  left: 32px;
  bottom: 32px;
}

.timeLineEvents__item__img img {
  object-fit: cover;
  border-radius: 16px;
  width: 176px;
  height: 88px;
  filter: grayscale(100%);
}

.timeLineEvents__item:hover .timeLineEvents__item__img {
  opacity: 0;
}

.s_about {
  display: flex;
  padding: 0 80px;
  padding-top: 149px;
  padding-bottom: 119px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.02em;

  /* Gray 1 */

  color: #333333;
}

.s_about__left p:first-child {
  margin-bottom: 20px;
}

.s_about__left,
.s_about__right {
  width: 50%;
}

.s_about__left p,
.s_about__right p {
  width: 490px;
}

.s_about__right {
  padding-top: 171px;
}

.s_about__WWO {
  margin-bottom: 33px;
  width: 210px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6e6e3;
  border-radius: 30px;

  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */

  letter-spacing: -0.05em;

  color: #131013;
}

.s_advisory {
  background: #e6e6e3;
  border-radius: 40px;
  padding: 96px 0px 96px 0px;
  max-width: 1920px;
  margin: 12px auto !important;
}

@media screen and (max-width: 1944px) {
  .s_advisory {
    max-width: calc(100vw - 24px);
    margin: 12px !important;
  }
}

.s_advisory path {
  fill: #333333;
}

.s_team path {
  fill: #333333;
}

.s_advisory__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 34px */

  letter-spacing: -0.05em;
  margin-bottom: 81px;
  color: #131013;
}

.s_advisory__content {
  /* display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 120px 187px; */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between;
    flex-flow: row wrap; */
}

/* .s_advisory__content::after {
    content: "";
    flex: auto;
  } */
.s_advisory__content__item {
  width: 300px;
  margin-bottom: 130px;
  margin-right: 188px;
}

.s_advisory__content__item:nth-child(3n) {
  margin-right: 0;
}

.s_team__row__item {
  width: 300px;
  margin-bottom: 130px;
  margin-right: 207px;
}

.s_team__row__item-grey {
  background-color: #e6e6e3;
  color: #131013;
  border-radius: 20px;
  padding: 32px;
  position: relative;
  border: 1px solid #131013;
  /* min-width: 320px; */
  margin-right: 187px;
}

.add_chapter_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 24px;
}

.add_chapter_desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.03em;
}

.add_chapter_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  left: 32px;
  bottom: 32px;
  border: 1px solid #131013;
  color: #131013;
  padding: 0 32px;
  transition: all 0.3s linear;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 12px;
  height: 48px;
}

.add_chapter_btn:hover {
  background-color: #131013;
  color: #fff;
}

.s_team__row__item:nth-child(3n) {
  margin-right: 0;
}

.s_advisory__content__item__img {
  width: 162px;
  height: 186.44px;
  border-radius: 24px;
  margin-bottom: 22px;
  border-radius: 24px;
}

.s_advisory__content__item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
}

.s_advisory__content__item__name {
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 11px;
  color: #131013;
  width: fit-content;
}

.s_advisory__content__item__name__flag {
  margin-left: 9px;
  width: 28px;
  height: 34px;
  background: transparent;
  position: absolute;
  top: -5px;
  right: -36px;
}

.s_advisory__content__item__name__flag img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  background: transparent;
}

.s_advisory__content__item__descr {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.05em;
  margin-bottom: 22px;
  color: #333333;
}

.s_advisory_chapter_last_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.s_advisory_chapter_btn {
  border-radius: 12px;
  border: 1px solid #c5c5c2;
  display: flex;
  padding: 10px 16px;
  width: fit-content;
  color: #252627;
  margin-left: 18px;
}

.s_advisory_chapter_btn:hover {
  transition: all 0.3s linear;
  background: #c5c5c2;
}

.s_advisory_chapter_btn_title {
  color: inherit;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.8px;
  margin-right: 8px;
}

.s_team {
  padding: 92px 80px 120px 80px;
}

.s_team__row {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.faq_S {
  width: 955px;

  margin: 0 auto;
  padding-top: 76px;
  padding-bottom: 51px;
}

.faq_s__head {
  display: flex;
  margin: 0 auto;
  width: fit-content;
}

.faq_s__head__link {
  padding: 11px 20px;
  background: inherit;
  mix-blend-mode: normal;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: -0.03em;
  transition: all 0.3s linear;
  color: #131013;
  cursor: pointer;
  margin-right: 40px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.faq_s__head__link:last-child {
  margin-right: 0;
}

.faq_s__head__link:hover {
  scale: 1.2;
}

.faq_s__head__link_selected {
  background: #e6e6e3;
}

.faq_s__head__link_selected:hover {
  scale: 1;
}

.questionsWrap {
  width: 100%;
  min-height: 700px;
  margin-top: 80px;
  overflow: hidden;
}

.question {
  margin-bottom: 24px;
  position: relative;
  width: 100%;
  height: 109px;
  border: 1px solid #e6e6e3;
  border-radius: 32px;
  padding: 0 40px;
}

@media screen and (max-width: 660px) {
  .question {
    margin-bottom: 24px;
    position: relative;
    width: 100%;
    height: unset;
    cursor: pointer;
    border: 1px solid #e6e6e3;
    border-radius: 32px;
    padding: 12px 40px;
  }
}

.question__front {
  display: flex;
  width: 100%;
  height: 110px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  letter-spacing: -0.05em;
  cursor: pointer;
  color: #131013;

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.question__front span {
  padding-right: 16px;
}

.question__front path {
  margin-left: 30px;
}

.question__front:has(.minus) .minus {
  margin-top: -10px;
  scale: 0.9;
}

.question_opened {
  min-height: 178px;
  height: 100%;
  background: #e6e6e3;
}

.question__answer {
  display: none;
  padding: 0 0 34px 0;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;

  /* Gray 1 */

  color: #333333;
}

.question_opened .question__answer {
  display: block;
}

.question__answer a {
  position: relative;
  color: #4141ff;
  text-decoration: none;
}

.question__answer a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 1.5px;
  background: #4141ff;
}

.dealFlowWhite-enter {
  transform: translateX(-100%);
  /* background-color:red; */
}

.dealFlowWhite-enter-active {
  transform: translateX(0);
  transition: 1.3s all ease;
  /* background-color:green; */
}

.dealFlowWhite-exit {
  transform: translateX(0%);

  /* background-color:blue; */
  opacity: 1;
}

.dealFlowWhite-exit-active {
  opacity: 0;
  transform: translateX(+100%);
  transition: 1.3s all ease;
  /* background-color:black; */
}

.footer__black__mob {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-bottom: 62px;
  min-height: 405px;
}

.footer__black__mob-nav {
  display: flex;
}

.footer__address {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
}

.footer__address-icon {
}

.footer__address-text {
  color: #8a8a88;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-left: 12px;
}

@media screen and (max-width: 1600px) {
  .footer__address-text {
    max-width: 300px;
  }
}

@media screen and (max-width: 1439px) {
  .footer__address-text {
    max-width: unset;
  }
}

.secDealFlow {
  position: relative;
  z-index: 1;
  margin-top: -65px;
  padding-top: 160px;
}

.sectionWrap_footer__black {
  min-height: 368px;
  height: 100%;
  position: relative;
  display: flex;
  margin: 0 auto;
  max-width: 1484px;
  width: 100%;
}

.secAboutUs_config {
  position: relative;
  /* margin-top: -50vh !important; */
  /* z-index: 200; */
}

.secAboutUs__right__item__desrc .textStar {
  line-height: 0px;
  margin-left: 2px;
}

.aboutUs_startext {
  margin-top: 42px;
}

@media (min-width: 1601px) {
  /* .mainTop {
        min-height: 731.11px;
        height: 100%;
    } */
}

@media (max-width: 1600px) {
  .s__upcomingEvents .slider__wraper {
    width: 100%;
  }

  .s_advisory {
    padding: 80px 0px 133px 70px;
  }
}

@media (min-width: 2000px) {
  /* .mainTop {
        height: 100%;
        min-height: 831.11px;
    } */
}

@media (max-width: 1600px) {
  .s__upcomingEvents__head {
    max-width: 88vw;
    width: 100%;

    margin: 0 auto;
    margin-bottom: 96px;
  }
}

@media (max-width: 1560px) {
  .sectionWrap {
    max-width: 88vw;
    width: 100%;
  }

  .nav_scrolled {
    max-width: 88vw;
    width: 100%;
  }

  .nav_NoScrolled {
    max-width: 88vw;
    width: 100%;
  }

  .is-at-top {
    max-width: 88vw;
    width: 100%;
  }

  .footer__content {
    margin-bottom: 5px;
  }

  .getStrd__btn {
    margin-bottom: 122px;
  }

  /* .s_advisory__content {
        display: inline-flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 120px 140px;
    } */

  .wisinc__left__text {
    width: 100%;
  }

  .wisinc__left__flex__block {
    margin-bottom: 63px;
    max-width: 44%;
    width: 100%;
    margin-right: 15px;
  }

  .offerBlock__bottomRow__right__block {
    margin-right: 35px;
    margin-bottom: 30px;
  }
}

@media (max-width: 1450px) {
  .s_advisory__content__item {
    width: 300px;
    margin-bottom: 130px;
    margin-right: 10vw;
  }
}

@media (max-width: 1330px) {
  .modalBlock_open {
    right: 1%;
  }

  .footer__black__flex {
    display: flex;
    flex-direction: column;
  }

  .footer__black__mob {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .footer__black__social {
    margin-top: 30px;
  }

  /* .s_advisory__content__item:nth-child(3) .s_advisory__content__item__name {
        margin-right: 107px;
    } */
}

@media (max-width: 1340px) {
  .sectionWrap {
    max-width: 94vw;
    width: 100%;
  }

  .nav_scrolled {
    max-width: 94vw;
    width: 100%;
  }

  .nav_NoScrolled {
    max-width: 94vw;
    width: 100%;
  }

  .is-at-top {
    max-width: 94vw;
    width: 100%;
  }

  .modalBlock_open {
    right: 1%;
  }

  .section__criteria__left__text {
    width: 100%;
  }

  .section__criteria__left {
    width: 44%;
  }

  .section__criteria__fEnd {
    margin-bottom: 47px;
  }

  .section__criteria__right {
    margin-left: 85px;
    width: 50%;
    margin-top: 57px;
  }
}

/* @media(max-width:1285px){
    .s_advisory__content__item {
        width: 220px;
    }
} */
@media (max-width: 1465px) {
  .s_team__row__item {
    width: 265px;
    margin-right: 13vw;
  }
}

@media (max-width: 1300px) {
  .s_advisory__content__item {
    width: 255px;
    margin-right: 12vw;
  }

  /* .s_advisory__content__item:nth-child(3) .s_advisory__content__item__name {
        margin-right: 110px;
    } */

  .s_team__row__item {
    width: 255px;
  }
}

@media (max-width: 1220px) {
  .s_about__left,
  .s_about__right {
    width: 49%;
  }

  .s_about__left p,
  .s_about__right p {
    width: 95%;
  }

  .wisinc__right {
    width: 46%;
    margin-left: 111px;
    padding-left: 95px;
    padding-top: 46px;
    height: 450px;
  }

  .wisinc__right img {
    height: 90%;
  }

  .footer__content {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    margin-bottom: 142px;
  }

  .footer__content__left {
    width: 322px;
    margin-right: 0;
  }
}

@media (max-width: 1220px) {
  .modalBlock_open {
    right: 1%;
  }

  .section__criteria__right__item span {
    width: 85%;
  }

  .section__criteria__right {
    margin-left: 0;
    width: 52%;
  }

  .section__criteria__left {
    width: 44%;
    margin-right: 4%;
  }
}

@media (max-width: 1600px) {
  .s_team {
    padding: 92px 0px 120px 70px;
  }
}

@media (max-width: 1160px) {
  .s_advisory__content,
  .s_team__row {
    justify-content: space-between;
  }

  .s_advisory__content__item:nth-child(3n) {
    margin-right: 12vw;
  }

  .s_advisory__content__item {
    width: 264px;
    margin-bottom: 97px;
    margin-right: 13vw;
  }

  .s_team__row__item:nth-child(3n) {
    margin-right: 13vw;
  }

  .s_team__row__item {
    width: 264px;

    margin-bottom: 98px;
  }
}

@media (max-width: 1230px) {
  .offerBlock__bottomRow__right__block {
    width: 100%;
  }
}

@media (max-width: 1130px) {
  .secAboutUs__left {
    width: 48%;
  }

  .s__upcomingEvents__head {
    margin-bottom: 96px;
  }

  .secAboutUs__right__item__count {
    /* font-size: 48px; */
  }

  .secAboutUs__right__item {
    /* min-width: 240px; */
  }

  .faq_S {
    width: 90vw;
  }

  .faq_S .sectionWrap {
    max-width: 90vw;
    width: 100%;
  }
}

@media (max-width: 940px) {
  .secAboutUs__right__item {
    width: 100%;
  }

  .secAboutUsContent {
    display: flex;
    flex-direction: column;
    margin-top: 96px;
  }

  .secAboutUs__left {
    width: 100%;
  }

  .secAboutUs__right {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  .section__criteria__right__item {
    width: 100%;
  }

  .guys {
    max-width: 100vw;
    overflow: hidden;
  }

  .guys .sectionWrap {
    flex-direction: column;
    align-items: center;
  }

  .guysSectionWrap {
    max-width: 80vw;
    align-items: flex-start !important;
  }

  .guysPhotos {
    width: 100vw;
  }

  .becomeMember {
    padding: 0;
    max-width: 66.6vw;
    margin-left: 23.333vw;
  }

  .guysPhotos {
    margin-bottom: 80px;
    margin-left: 0;
  }

  .guysPhotosText {
    width: 100%;
    /* text-align: center; */
    margin: 0;
  }

  .becomeMember .sectionWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .becomeMember__text {
    /* text-align: center; */
  }

  .bigWhyUs {
    margin-bottom: 100px;
    width: 90vw;
    height: 206px;
  }

  .section__criteria {
    padding-top: 66px;
    /* padding-left: 40px;
        padding-right: 30px; */
    padding-bottom: 103px;
  }

  .becomeMember__btn {
    padding: 16px 0;
    width: 100%;
    max-width: 200px;
  }
}

@media (max-width: 1110px) {
  .wisinc__right {
    width: 46%;
    margin-left: 111px;
    padding-left: 75px;
    padding-top: 46px;
    height: 430px;
  }

  .offerBlock__bottomRow__right {
    width: 47%;
  }

  .offerBlock__topRow__right {
    width: 50%;
  }
}

@media (max-width: 1020px) {
  .modalBlock_open {
    right: 0%;
  }
}

@media (max-width: 1000px) {
  .section__criteria .displayFlexBlock {
    flex-direction: column;
  }

  .section__criteria__left {
    width: 100%;

    margin: 0;
    margin-bottom: 20px;
  }

  .section__criteria {
    margin-top: 60px;
  }

  .section__criteria__right {
    margin-left: 0;
    width: 66%;
    margin-top: -15px;
    margin-bottom: 110px;
  }

  .section__criteria__btn {
    margin: 0 auto;
    margin-top: 0;
  }

  .sectionWrap_footer__black {
    min-height: 700px;
    height: 100%;
  }

  .s_advisory__content__item {
    width: 264px;
    margin: 0 40px;
    margin-bottom: 97px;
  }

  .s_advisory__content__item:nth-child(3n) {
    margin-right: 0;
  }

  .offerBlock__topRow__right {
    width: 100%;
  }

  .offerBlock__topRow__left {
    margin-bottom: 80px;
  }

  .offerBlock__topRow {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  .offerBlock__bottomRow {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: space-between;
  }

  .offerBlock__bottomRow__right {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 60px;
  }

  .offerBlock__bottomRow__right__block {
    width: 35%;
  }

  .wisinc__right {
    width: 46%;
    margin-left: 32px;
    padding-left: 75px;
    padding-top: 46px;
    height: 360px;
  }

  .wisinc__left__text {
    font-size: 38px;
  }
}

@media (max-width: 1060px) {
  .secAboutUs .sectionWrap {
    flex-direction: column;
  }

  .secAboutUs__left {
    width: 100%;
  }

  .secAboutUs__left__text {
    width: 90%;
    margin-bottom: 96px;
  }

  .secAboutUs__right {
    width: 100%;
  }

  .aboutUs_startext {
    margin-top: 96px;
  }

  .secAboutUs__right__flex {
    margin-top: 72px;
    justify-content: space-between;
  }
}

.nav_mob_opened {
  align-items: flex-start;
  background: #e6e6e3;
  /* min-height: 432px; */
  height: fit-content;
  width: calc(100vw - 24px) !important;
  max-width: calc(100vw - 24px) !important;
  border-radius: 40px !important;
  left: 12px !important;
  top: 12px;
  position: fixed;
  margin-top: -1px;
  padding: 33.25px 32px 32px !important;
}

.nav__registration_mob {
  display: none;
}

.nav_flex_mob {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1000px) {
  .nav_mobileBtn {
    display: flex;
  }

  .nav__nav .nav__nav__link {
    display: none;
  }

  .nav__registration {
    display: none;
  }

  .nav__registration_mob {
    display: flex;
    position: relative;
    margin-top: 50px;
  }

  .nav_mob_opened .nav__nav_mob {
    display: flex;
  }

  .nav_flex_mob {
    margin-top: 19px;
  }

  .nav {
    display: block;
  }

  .nav_mob_opened .nav {
    border-radius: 32px;
  }

  .nav__nav_mob {
    margin-top: 81px;
    /* margin-bottom: 56px; */
  }
}

@media (max-width: 900px) {
  .grey__section {
    padding: 96px 0;
  }
}

@media (max-width: 500px) {
  .secAboutUs__left__text {
    width: 100%;
    margin-bottom: 60px;
  }

  .secAboutUs {
    padding: 0 30px;
  }

  .secAboutUs__right {
    width: 100%;
    margin: 0 auto;
  }

  .secAboutUs__left {
    width: 100%;
    /* padding: 0 30px; */
  }

  .secAboutUs__right__item {
    margin-bottom: 25px;
  }
}

@media (max-height: 960px) {
  /* .secAboutUs_config {
        margin-top: -30vh !important;
    } */
}

@media (max-height: 830px) {
  .modalBlock {
    overflow-y: scroll;
  }

  .modalBlock__content:after {
    background: linear-gradient(180deg, rgba(246, 246, 246, 0) 0%, #fff 100%);
  }

  /* .modalBlock__content__wrapper {
        width: 100%;
        padding-right: 20px;
    } */

  .modalBlock__content__head__title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    max-width: 85%;
  }

  .modalBlock__content {
    position: relative;
    display: flex;
    min-height: 100%;
    height: auto;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px;
    padding-bottom: 0;
  }

  .btnModalGrey {
    position: sticky;
    bottom: 32px;
    left: 50%;
    transform: translateX(0);
    width: 100%;
    margin-bottom: -75px;
  }

  .modalBlock__content__wrapper {
    min-height: 75vh;
  }

  .modalBlock__content__logo {
    width: 100%;
    height: 150px;
    margin-bottom: 80px;
  }

  /* .section__OurMembersGet .h4_secitonTitle{
        margin-top: -70px;
    }
    .timeLine{
        padding-top: 70px;
    } */
}

@media screen and (max-width: 830px) {
  .modalBlock__content__wrapper {
    padding-right: 32px;
  }
}

@media (max-width: 800px) {
  .s_advisory__content__item {
    width: 54%;
    margin: 0 50px !important;
    margin-bottom: 97px !important;
  }

  .s_advisory_chapter_last_row {
    justify-content: center;
  }

  .s_advisory {
    padding: 80px 70px 133px 70px;
  }

  .s_advisory__content {
    justify-content: center;
  }

  .s_advisory__content__item:nth-child(3) .s_advisory__content__item__name {
    width: fit-content;
  }

  .s_advisory__content__item__name {
    margin: 0 auto;
    margin-bottom: 11px;
    width: -moz-fit-content;
  }
}

@media (max-height: 690px) {
  .mainTop {
    min-height: 100vh;
  }
}

@media (max-width: 1000px) {
  .sectionWrap_footer__black {
    min-height: 960px;
    height: 100%;
  }

  .footer__black__mob-nav {
    display: flex;
    flex-direction: column;
  }

  .footer__black__flex__col-left {
    margin-bottom: 40px;
  }

  .footer__black__flex__col {
    margin-right: 0;
  }

  .speaky {
    position: absolute;
    width: 376px;
    /* height: 319px; */

    left: 0;
    /* right: 49px; */
    top: 410px;
  }

  .footer__right__container {
    position: relative;
    width: 100%;
  }
}

@media (max-width: 888px) {
  .questionsWrap {
    width: 100%;
    min-height: 300px;
  }

  .ourMembersGetTitleMob .slider__nav {
    display: flex;
  }

  .section__OurMembersGet {
    margin-bottom: 75px;
  }

  .section__OurMembersGet .timeLine__item {
    height: 450px;
    flex: 0 0 360px;
    margin-right: 0;
  }

  /* .secAboutUs_config {
        margin-top: -5px !important;
    } */

  .becomeMember__text {
    margin-top: 31px;
    margin-bottom: 30px;
  }

  .s_team__row__item {
    width: 255px;
    margin: 0 10px;
    margin-bottom: 80px;
  }

  .s_team__row__item:nth-child(3n) {
    margin: 0 10px;
    margin-bottom: 80px;
  }

  .s_about .sectionWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .s_about__left,
  .s_about__right {
    width: 90%;
  }

  .wisinc__right {
    width: 46%;
    margin-left: 65px;
    padding-left: 63px;
    padding-top: 30px;
    height: 335px;
    overflow: hidden;
  }

  .sectionWisinc {
    padding: 0 40px;
    padding-bottom: 134px;
  }

  .wisinc__left__text {
    font-size: 36px;
    line-height: 45px;
  }

  .sectionWisinc {
    padding: 0 49px;
    padding-bottom: 94px;
  }
}

@media (max-width: 800px) {
  .s_team__row__item {
    width: 300px;
    margin: 0 30px;
    margin-bottom: 90px;
  }

  .s_team__row__item-grey {
    width: 100%;
    margin: 0 !important;
    min-height: 360px;
    margin-bottom: 97px !important;
    max-width: 360px;
  }

  .s_team__row__item:nth-child(3n) {
    width: 300px;
    margin: 0 30px;
    margin-bottom: 90px;
  }

  .s_team {
    padding: 80px 70px 133px 70px;
  }

  .s_team__row a {
    display: flex;
    justify-content: center;
  }

  .s_team__row {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .s_about__right {
    padding-top: 100px;
  }

  .s_advisory__content__item__img {
    width: 162px;
    height: 186.44px;
    border-radius: 24px;
    margin: 0 auto;
    margin-bottom: 22px;
  }

  .s_advisory__content__item__name {
    text-align: center;
    justify-content: center;
  }

  .s_advisory__content__item__descr {
    text-align: center;
  }

  .s_advisory__content__item a {
    display: flex;
    justify-content: center;
  }

  .wisinc {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .wisinc__right {
    position: absolute;
    top: 190px;
    right: 0;
    width: 59%;
    padding-left: 73px;
    padding-top: 30px;
    height: 429px;
    overflow: hidden;
  }

  .wisinc__left {
    width: 80%;
    padding-top: 490px;
  }

  .wisinc__left__flex {
    width: 85vw;
  }
}

@media (max-width: 730px) {
  .steps {
    width: 85vw;
  }

  .step__left {
    width: 46%;
  }

  .step__block__count {
    margin-right: 19px;
  }

  .step__block__text {
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px);
  }

  .step__block {
    padding: 35px 30px 35px 30px;
  }

  .slider__top {
    flex-direction: column;
    margin-top: 10px;
  }

  .slider__title {
    margin-bottom: 96px;
  }

  .slider__nav {
    display: flex;
    margin-bottom: 50px;
  }

  .slider__nav_desktop {
    display: none;
  }

  .slider__nav_mobile {
    display: flex !important;
    margin-top: 96px;
    margin-bottom: 0;
  }

  .s__upcomingEvents__head {
    flex-direction: column;
    margin-bottom: 96px;
  }

  .section__OurMembersGet2 .ourMembersGetTitleMob {
    flex-direction: column;
    margin-bottom: 0;
  }

  .s__upcomingEvents .h4_secitonTitle,
  .section__OurMembersGet2 .h4_secitonTitle {
    /* margin-bottom: 40px; */
  }
}

.slider__nav_mobile {
  display: none;
}

@media (max-width: 1000px) {
  .offerBlock__bottomRow__left {
    width: 100%;
    margin-bottom: 0;
  }
}

@media (max-width: 660px) {
  .s_advisory__content__item__img {
    display: flex;
    justify-content: center;
  }

  .section__slider {
    padding: 0 32px;
    padding-top: 96px;
    padding-bottom: 96px;
  }

  .step__block_dealFlow {
    flex-direction: column;
    padding: 20px 40px 25px 40px;
  }

  .wisinc__right {
    top: 190px;
    right: 0;
    width: 58%;
    padding-left: 60px;
    padding-top: 30px;
    height: 359px;
  }

  .wisinc__left {
    width: 85%;
    padding-top: 438px;
  }

  .wisinc__left__text {
    font-size: 34px;
    line-height: 45px;
  }

  .step__block_dealFlow .step__left {
    width: 100%;
    margin-bottom: 20px;
  }

  .step__block_dealFlow .step__right {
    width: 100%;
    margin-bottom: 0;
  }

  .step__right__block__title {
    margin-bottom: 28px;
  }

  .secAboutUs {
    padding: 0 44px;
  }

  .section__container {
    margin: 0 auto;
    padding: 0 44px;
  }

  .section__list {
    margin-top: 85px;
  }

  .modalBlock {
    width: 96vw;
    height: 90vh !important;
  }

  .modalBlock_open {
    right: 2%;
  }

  .aboutUs_startext {
    margin-bottom: 96px;
  }

  .footer__content__left {
    width: 60vw;
    margin: 0 auto;
  }

  .sectionWrap_footer__black {
    /* max-width: 70vw; */
  }

  .speaky {
    position: absolute;
    width: 100%;
    /* height: 319px; */
    right: 0;
    left: 0;
    bottom: 184px;
  }

  /* .nav {
        padding-left: 30px;
        padding-right: 20px;
    } */

  .section__criteria__right {
    margin-left: 0;
    width: 100%;
    margin-top: -30px;
    margin-bottom: 95px;
  }

  .top__h1 {
    font-size: 52px;
  }

  .offerBlock__topRow__right {
    margin-bottom: 60px;
    font-size: 36px;
  }

  .offerBlock__bottomRow__right__block {
    width: 98%;
  }

  .offerBlockWrap {
    width: 100%;
    max-width: 1420px;
    margin: 0 auto;
    padding: 64px 44px;
  }

  .offerBlock__bottomRow__left {
    width: 100%;
    margin-bottom: 0;
  }

  .offerBlock__bottomRow__left__block {
    width: 100%;
  }

  .offerBlock {
    margin-top: 60px;
  }

  .bigWhyUs {
    margin-bottom: 100px;
    width: 90vw;
    height: 163px;
    font-size: 42px;
  }
}

@media (max-width: 600px) {
  .step__block__text p {
    width: 125%;
    margin-left: -48px;
  }

  .s_team {
    padding: 80px 45px;
  }

  .s_about__left,
  .s_about__right {
    width: 98%;
  }

  .offer__title {
    width: 85vw;
  }

  .becomeMember {
    width: 90vw;
    margin-bottom: 131px;
  }

  .s_about__right {
    padding-top: 60px;
  }

  .footer__black {
    padding-left: 32px;
    padding-right: 32px;
  }

  .footer__black__flex__col {
    margin-right: 58px;
  }

  .speaky {
    position: absolute;
    width: 76vw;
  }

  .faq_s__head {
    display: flex;
    justify-content: space-between;
    /* margin: 0 20px;
        width: calc(100% - 40px); */
  }

  .faq_s__head__link {
    padding: 9px 8px;
    margin-right: 16px;
  }

  .faq_s__head__link:hover {
    scale: 1;
  }

  .step__block__text__title {
    font-size: 22px;
    margin-bottom: 24px;
  }
}

@media (max-width: 550px) {
  .offerSection__container .step__block {
    min-height: 210px;
    padding: 36px 30px 42px 25px;
  }

  .wisinc__left__flex__block {
    margin-bottom: 63px;
    max-width: 41%;
    width: 100%;
    margin-right: 20px;
  }

  .mainTop__title {
    font-size: 28px;
    margin-bottom: 35px;
  }

  .wisinc__right {
    top: 190px;
    right: 0;
    width: 62%;
    padding-left: 72px;
    padding-top: 30px;
    height: 311px;
  }

  .wisinc__left {
    width: 95%;
    padding-top: 366px;
  }

  .mainTop__AboutBtn,
  .mainTop__becomeBtn {
    width: 100%;
  }

  .mainTop__blue__bot {
    flex-direction: column;
  }

  .mainTop__becomeBtn {
    margin: 0;
    margin-bottom: 20px;
  }

  /* .mainTop__blue {
        left: 2vw;
        padding: 30px;
        width: 90vw;
        height: fit-content;
        bottom: -97vh;
    } */

  .nav {
    padding: 0 30px;
  }

  .nav_mob_opened {
    padding-top: 36px;
    /* top: -13px; */
  }

  .footer__right {
    width: 300px;
  }

  .footer__right__opaciti1 {
    width: 87%;
  }

  .footer__right__opaciti2 {
    width: 87%;
  }

  .footer__right__container {
    margin-left: 45px;
  }

  .getStrd__btn {
    padding: 16px 81px;
    margin-bottom: 53px;
  }

  .footer__content__left__title {
    font-size: 42px;
  }

  .footer__attention {
    width: 120px;
    bottom: 2px;
    right: 109px;
    font-size: 14px;
    margin-left: 10px;
    color: #ffffff;
  }

  .footer__attention {
    width: 110px;
    bottom: 2px;
    right: 19vw;
    font-size: 13px;
    margin-left: 10px;
  }

  .s_advisory__content__item {
    width: 100%;
    margin: 0 0px !important;
    margin-bottom: 97px !important;
    max-width: 300px;
  }

  .s_advisory__content__item:nth-child(3)
    .s_advisory__content__item__name__flag {
    right: -40px;
  }

  .s_advisory {
    padding: 80px 33px;
  }

  .guysPhotosText {
    width: 100%;
    font-size: 32px;
    line-height: 40px;
  }

  .bigWhyUs {
    margin-bottom: 100px;
    width: 90vw;
    height: 130px;
    font-size: 36px;
  }

  .bigWhyUs svg {
    scale: 0.7;
  }

  .guysPhotos__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
  }

  .guysPhotos__circle img {
    object-fit: contain;
    width: 85px;
    height: 85px;
  }

  .guysPhotos__circle:first-child {
    margin-left: 0;
    outline: none;
  }

  .guysPhotos__circle:first-child img {
    width: 78px;
    height: 78px;
  }

  .guysPhotos__circle:last-child {
    outline: 4px solid #ffffff;
  }
}

@media (max-width: 480px) {
  .wisinc__right img {
    height: 100%;
  }

  .wisinc__right {
    top: 190px;
    right: 0;
    width: 100%;
    padding-left: 72px;
    padding-top: 14px;
    height: 374px;
  }

  .modalBlock__content__head__title {
    font-size: 32px;
  }

  .wisinc__left {
    width: 95%;
    padding-top: 438px;
  }

  .wisinc__left__text {
    font-size: 32px;
    line-height: 40px;
  }

  .secTitle {
    margin-top: 106px;
    font-size: 24px;
  }

  .wisinc__left__flex {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .wisinc__left__flex__block {
    width: 100%;
    max-width: 100%;
    margin-bottom: 32px;
  }

  .footer__black__mob {
    flex-direction: column;
  }

  .footer__black__flex__col:first-child {
    margin-bottom: 20px;
  }

  .sectionWrap_footer__black {
    min-height: 960px;
    height: 100%;
  }

  .speaky {
    position: absolute;
    width: 80vw;
    left: 50%;
    transform: translateX(-50%);
    top: 390px;
  }

  .footer__black__flex__col {
    margin-right: 0;
  }

  .footer__black__flex__logo {
    margin-right: 0;
  }

  .footer__attention {
    width: 110px;
    bottom: 2px;
    right: 14vw;
  }

  .getStrd__btn {
    padding: 15px 0;
    width: 100%;
    max-width: 300px;
    margin-bottom: 53px;
    margin-right: 0;
  }

  .footer {
    overflow: hidden;
  }

  .section__list {
    margin-top: 80px;
    margin-bottom: 106px;

    font-size: 36px;
  }

  .step__block_dealFlow {
    flex-direction: column;
    padding: 9px 30px 25px 23px;
  }

  .section__criteria {
    /* 
        padding-left: 30px;
        padding-right: 30px; */
  }

  .offerBlock__bottomRow__left__text {
    font-size: 20px;
    width: 90%;
  }

  .offerBlock__topRow__right {
    font-size: 32px;
  }

  .offerBlockWrap {
    width: 100%;
    max-width: 1420px;
    margin: 0 auto;
    padding: 64px 32px;
  }

  .offerBlock__topRow__left {
    margin-bottom: 61px;
    width: 100%;
  }
}

@media (max-width: 412px) {
  .sectionWrap_footer__black {
    /* min-height: 950px; */
    height: 100%;
  }

  .speaky {
    /* height: 364px; */
    /* left: -18px; */
    bottom: 130px;
  }
}

@media (max-width: 400px) {
  .speaky__head {
    margin-bottom: 56px;
  }

  .speaky__text {
    font-size: 23px;
    line-height: 32px;
  }
}

@media (max-width: 430px) {
  .speaky {
    padding: 24px;
  }

  .faq_s__head {
    display: flex;
  }

  .s_about__left,
  .s_about__right {
    width: 100%;
  }

  .s_about {
    display: flex;
    padding: 0 45px;
    padding-bottom: 119px;
  }

  .s_advisory {
    margin-top: 70px;
  }

  .s_about {
    margin-top: 60px;
  }

  .bigWhyUs {
    margin-bottom: 100px;
    width: 90vw;
    height: 100px;
    font-size: 32px;
  }

  .bigWhyUs svg {
    scale: 0.5;
  }

  .bigWhyUs div {
    margin-left: 0px;
    padding-top: 8px;
  }

  .top__h1 {
    font-size: 46px;
  }

  .main_steps {
    width: 90vw;
  }

  .step__right__block__logo svg {
    scale: 0.7;
  }

  .main_steps .step__right__block__title {
    margin-bottom: 10px;
  }

  .step__right__block__logo {
    margin-bottom: -20px;
  }

  .step__block__text__deaflow {
    margin-bottom: 15px;
  }

  .main_steps .step__block_dealFlow__count {
    margin-top: 17px;
    margin-bottom: 18px;
  }

  .section__list {
    margin-top: 80px;
    margin-bottom: 40px;
    font-size: 36px;
  }

  .sliderWrap__OurMembersGet {
    max-width: 1288px;
  }

  .timeLine__item {
    max-width: 310px;
    min-width: 310px;
  }
}

@media (max-width: 410px) {
  .footer__attention {
    width: 110px;
    bottom: 1px;
    right: 10vw;
  }

  .questionsWrap {
    width: 100%;
    min-height: 700px;
    margin-top: 60px;
  }

  .modalBlock__content {
    padding: 24px;
  }

  .modalBlock__content__wrapper {
    padding-right: 0;
  }

  .modalBlock__content__head__title {
    font-size: 28px;
  }

  .modalBlock__content__description {
    font-size: 15px;
  }

  .secAboutUs {
    padding: 0px 25px;
  }

  .secAboutUs__left__text {
    font-size: 28px;
  }

  .secAboutUs__right__item__count {
    /* font-size: 42px; */
  }

  .section__bottom__smallText {
    font-size: 14px;
  }

  .aboutUs_startext {
    margin-bottom: 96px;
    margin-top: 40px;
  }

  .section__criteria__right {
    margin-left: 0;
    width: 98%;
  }

  .section__criteria__right__item {
    padding: 25px 0;
    padding-left: 26px;
    padding-right: 42px;
  }

  .section__criteria__right__item span {
    font-size: 18px;
  }

  .timeLineEvents__item {
    width: 320px;
  }

  .getStrd__btn {
    padding: 15px 0px;
    max-width: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .sectionWisinc {
    padding: 0px 40px;
    padding-bottom: 50px;
  }

  .mainTop__blue {
    left: 14px;
    padding: 30px;
    width: calc(100vw - 50px);
    height: fit-content;
    bottom: -96.3vh;
  }
}

@media (max-width: 390px) {
  .footer__attention {
    width: 110px;
    bottom: 1px;
    right: 5vw;
  }

  .secTitle {
    margin-top: 106px;
    font-size: 24px;
  }

  .wisinc__left__text {
    font-size: 30px;
    line-height: 40px;
  }

  .wisinc__left {
    width: 100%;
    padding-top: 420px;
  }

  .wisinc__right {
    top: 198px;
    right: 0;
    width: 100%;
    padding-left: 72px;
    padding-top: 14px;
    height: 330px;
  }

  .footer__right {
    width: 276px;
  }

  .guysPhotosText {
    width: 90%;
    font-size: 30px;
  }
}

@media (max-width: 375px) {
  .s_advisory__content__item:nth-child(3)
    .s_advisory__content__item__name__flag {
    right: -3%;
  }
}

@media (max-width: 360px) {
  .section__criteria__right__item span {
    font-size: 16px;
    padding-left: 15px;
    max-width: 80%;
    width: 80%;
  }

  .footer__attention {
    width: 110px;
    bottom: 1px;
    right: 4vw;
  }
}

@media (max-width: 345px) {
  .s_advisory__content__item:nth-child(3)
    .s_advisory__content__item__name__flag {
    right: -8%;
  }
}

.pageLoader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageLoader .lds-ellipsis {
  padding: 0;
  height: auto;
}

.members_swiper {
  height: fit-content !important;
}

@media screen and (max-width: 410px) {
  .ourMembersGetTitleMob {
    margin-left: 25px;
  }

  .sliderWrap__OurMembersGet {
    width: 1488px;
    transform: unset;
    margin-left: 25px;
  }
}

.upcoming_wrapper_xl {
  width: 1392px;
}

.upcoming_wrapper_md {
  width: 1040px;
}

.upcoming_wrapper_sm {
  width: 688px;
}

.upcoming_wrapper_s {
  width: 336px;
}

/* DEALFLOW */

.dealflow {
  max-width: 1624px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.dealflowContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 70px;
}

.dealflowRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 500px;
}

.dealflowCard-simple {
  max-width: 285px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.dealflowCard-simple_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.03em;
  color: #252627;
  white-space: nowrap;
}

.dealflowCard-simple_title_desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.03em;
  color: #8a8a88;
  white-space: nowrap;
}

.dealflowCard {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 285px;
  border-radius: 32px;
  height: 100%;
  margin-left: 20px;
}

.dealflowCardFirst {
  max-height: 500px;
}

.dealflowCardSecond {
  max-height: 424px;
}

.dealflowCardThird {
  max-height: 368px;
}

.dealflowCardOrder {
  width: 40px;
  height: 40px;
  background: #4141f5;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}

.dealflowCardTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 24px;
  color: #252627;
  letter-spacing: -0.01em;
  min-height: 56px;
}

.dealflowCardMiddle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #8a8a88;
  display: flex;
  flex: 1;
}

.dealflowCardBottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.dealflowCardBottomTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.035em;
  color: #252627;
}

.dealflowCardBottomDesc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #8a8a88;
}

@media screen and (max-width: 1210px) {
  .dealflowContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .dealflowRow:first-child {
    height: fit-content;
    margin-bottom: 96px;
  }

  .dealflowCardFirst {
    margin-left: 0;
  }
}

@media screen and (max-width: 960px) {
  .dealflowRow:nth-child(2) {
    height: unset;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .dealflowCard {
    height: 400px;
    margin: 0 auto 20px auto;
  }
}

@media screen and (max-width: 660px) {
  .dealflowContainer {
    padding: 0 32px;
  }
}
.lineContainerLeftDecor {
  position: absolute;
  height: 100%;
  left: -2px;
  background: linear-gradient(90deg, #131013 0%, rgba(19, 16, 19, 0) 100%);
  min-width: 204px;
  z-index: 2;
}

.lineContainerRightDecor {
  position: absolute;
  height: 100%;
  right: -2px;
  background: linear-gradient(270deg, #131013 0%, rgba(19, 16, 19, 0) 100%);
  min-width: 204px;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .lineContainerLeftDecor {
    min-width: 102px;
    z-index: 2;
  }

  .lineContainerRightDecor {
    min-width: 102px;
    z-index: 2;
  }
}
/* RUNNING_LINE */
.RunniglineContainer {
  position: relative;
}

.lineContainer {
  max-width: 1484px;
  overflow: hidden;
}

.runningLineItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 224px;
  height: 96px;
  margin: 0 20px;
}

.runningLineItem img {
  width: 224px;
  height: 96px;
  object-fit: contain;
  object-position: center;
}

.marquee__content {
  flex-direction: row;
  flex-shrink: 0;
  display: flex;
  min-width: 100%;
  animation: scroll 50s linear infinite;
}

.sectionUnderFooter {
  border-top: 1.5px solid #252627;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  min-height: unset !important;
}

.underFooterText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #8a8a88;
  margin-bottom: 32px;
}

.underFooterLogos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-right: -20px;
}

.underFooterLogo {
  background: #1b1a1b;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(20% - 20px);
  margin-right: 20px;
  margin-bottom: 20px;
  height: 120px;
  min-height: 120px;
  min-width: 215px;
  color: #515151;
  cursor: pointer;
  transition: background-color 0.3s linear, color 0.3s linear;
  position: relative;
}

.underFooterLogo:hover {
  color: #fff;
}

.underFooterLogo svg {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
  height: auto;
  max-height: 120px;
  object-fit: cover;
}

@media screen and (max-width: 1360px) {
  .underFooterLogo {
    width: calc(25% - 20px);
  }
}

@media screen and (max-width: 1360px) {
  .underFooterLogos {
    justify-content: flex-start;
  }
  .underFooterLogo {
    width: calc(33.3333333% - 20px);
  }
}

@media screen and (max-width: 660px) {
  .underFooterLogos {
    flex-direction: column;
    margin-right: 0;
  }

  .underFooterLogo {
    width: 100%;
    margin-right: 0;
    color: #fff;
  }
}

.sectionWrapAdvisory {
  margin: 0 auto -130px auto;
}

.sectionWrapTeam {
  margin: 0 auto -98px auto;
}

@media screen and (max-width: 800px) {
  .sectionWrapAdvisory {
    margin: 0 auto -80px auto;
  }
  .sectionWrapTeam {
    margin: 0 auto -74px auto;
  }
}
