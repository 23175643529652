.container {
  margin-top: 80px;
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-bottom: 40px;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -1.4px;
}

.description {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}

@media screen and (max-width: 718px) {
  .container {
    max-width: calc(100vw - 88px);
  }
}
