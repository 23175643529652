.cookieWrapper {
  max-width: 474px;
  width: 100%;
  background: #FFFFFF;
  padding: 8px 8px 8px 16px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.cookieText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8A8A88;
}

.cookieLink {
  color: #131013;
  text-decoration: underline;
  cursor: pointer;
}

.cookieButton {
  color: #252627;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border: 1px solid #252627;
  border-radius: 12px;
  margin-left: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  transition: .3s all ease-in-out;
}

.cookieButton:hover {
  background-color: #131013;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .cookieWrapper {
    bottom: 12px;
    max-width: calc(100vw - 24px);
  }
}
